// src/PayPalButton.js

import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

const PayPalButton = () => {
    // const navigate = useNavigate();
    useEffect(() => {
        // Load the PayPal script dynamically
        const script = document.createElement('script');
        script.src = "https://www.paypal.com/sdk/js?client-id=AW2eYMJNnOheK8TAvbnzY8iJalIsg78_uBvg_2y97OE9hbz4JNBCCRqXC_SJn85IYbt3IKtEWggpKa_a&currency=USD"; // Replace with your client ID
        script.async = true;
        script.onload = () => {
            window.paypal
                .Buttons({
                    createOrder: (data, actions) => {
                        return actions.order.create({
                            purchase_units: [{
                                amount: {
                                    value: '10.00', // Replace with your transaction amount
                                },
                            }],
                        });
                    },
                    onApprove: async (data, actions) => {
                        const order = await actions.order.capture();
                        // navigate('https://quicpay.io/');
                        window.location.href = 'https://quicpay.io';
                        console.log('Order', order);
                        // Handle successful payment here (e.g., update your server)
                    },
                    onError: (err) => {
                        console.error('PayPal Checkout onError', err);
                    },
                })
                .render('#paypal-button-container'); // Renders the PayPal button
        };
        document.body.appendChild(script);

        // Clean up the script when the component is unmounted
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return <div id="paypal-button-container"></div>;
};

export default PayPalButton;
